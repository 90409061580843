/* src/app/shared/components/qr-code-preview/qr-code-preview.component.scss */
:host img {
  max-height: 1000px;
}
:host .preview {
  padding-top: 2rem;
  max-height: calc(100vh - 200px);
}
:host .preview-svg {
  text-align: center;
}
/*# sourceMappingURL=qr-code-preview.component.css.map */
