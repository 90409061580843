/* src/app/shared/components/localization-grid-quill-editor/localization-grid-quill-editor.component.scss */
:host .flag {
  max-width: 20px;
  margin-right: 0.25rem;
}
:host ::ng-deep .ql-editor {
  min-height: 70px;
  max-height: 70px;
}
:host ::ng-deep .k-tabstrip {
  height: 160px;
}
:host ::ng-deep .k-tabstrip-content {
  box-shadow: none;
  border: none;
  padding: 1px 4px;
  background-color: transparent;
}
/*# sourceMappingURL=localization-grid-quill-editor.component.css.map */
